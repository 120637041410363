import { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import Menu1 from "./partials/Menu1";
import { get } from "../../../api/backend";
import { post } from "../../../api/backend";
import { destroy } from "../../../api/backend";
import TextField from "@mui/material/TextField";
import "./Sidebar.css";
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { useHistory } from "react-router-dom";

const Sidebar = (props) => {

  let history = useHistory();

  const [activeElementIds, setActiveElementIds] = useState([]);

  const [menuFromApi, setMenuFromApi] = useState([]);

  //   const menuFromApi = [
  //     {
  //       id: 1,
  //       name: "Dashboard-head",
  //       parentId: null,
  //       icon: null,
  //       link: "/dashboard"
  //   ];

  const location = useLocation();

  // TODO: now I'm so tired and I should go to work tomorrow. I'll write it soon!
  const findMenuParents = (menuItems, pageLinkId, stack = []) => {
    // if(!parentId){
    //   return stack;
    // }
    // fill the stack
    if(pageLinkId !== null){
      return stack;
    }
    // console.log('starttt111: ' , stack);
    stack.push(pageLinkId);

    // change pageLinkId,
    // let allParentsFound = false;
    menuItems.forEach(item => {
      if (item.id == pageLinkId) {
        // if (!item.parentId) {
        //   allParentsFound = true;
        //   console.log('EEEEEEEEEEEEEEEeennnnnnnnndddddddddd')
        //   //return stack;
        // } else {
          pageLinkId = item.parentId
        // }
      }
    });

    // console.log('starttt222: ', stack);
    
    // console.log('starttt3333: ', stack);


    // return 'hiiiiii';

    // change parentId

    return findMenuParents(menuItems, pageLinkId, stack);
  }

  useEffect(() => {
    // console.log('location', location);
    const navbarData = get("/navbar-menus" + location.search);
    navbarData.then((data) => {
      // console.log('navvvvvbar: ', data.data.data)
      const menuItems = data.data.data;

      const pathname = window.location.pathname;
      if (pathname.includes('posts/')) {
        let breadcrumbPath = "";
        const parts = pathname.split('/');
        const pageLinkId = parts[parts.length - 1];
        let parentId = null;
        menuItems.forEach(item => {
          if (item.id == pageLinkId) {
            parentId = item.parentId;
            breadcrumbPath = item.name;
            // console.log('hhhhhhhhhhhhhhhhhhhhhkkkkkl 1', pageLinkId)
          }
          // console.log('jdsfkjkl 2')
        });
        let grandParentId = null;
        if (parentId) {
          //findMenuParents(menuItems, pageLinkId)
          // console.log('finaaaaaaaaaaal 1 ', pageLinkId);
          // console.log('finaaaaaaaaaaal 2 ', parentId);

          menuItems.forEach(item => {
            if (item.id == parentId) {
              grandParentId = item.parentId;
              breadcrumbPath = item.name + ' > ' + breadcrumbPath;
              // console.log('hhhhhhhhhhhhhhhhhhhhhkkkkkl 1', pageLinkId)
            }
            // console.log('jdsfkjkl 2')
          });

          // console.log('finaaaaaaaaaaal 3 ', parentId);
          // console.log('finaaaaaaaaaaal 3 ', grandParentId);
          // console.log('finaaaaaaaaaaal 4 ', breadcrumbPath);
        }
        if (grandParentId) {
          //findMenuParents(menuItems, pageLinkId)
          // console.log('finaaaaaaaaaaal 5 ', grandParentId);

          menuItems.forEach(item => {
            if (item.id == grandParentId) {
              // grandParentId = item.parentId;
              breadcrumbPath = item.name + ' > ' + breadcrumbPath;
              // console.log('hhhhhhhhhhhhhhhhhhhhhkkkkkl 1', pageLinkId)
            }
            // console.log('jdsfkjkl 2')
          });

          // console.log('finaaaaaaaaaaal 3 ', parentId);
          // console.log('finaaaaaaaaaaal 3 ', grandParentId);
          // console.log('finaaaaaaaaaaal 4 ', breadcrumbPath);
        }
        setParentMenus(breadcrumbPath);
      } else {
        setParentMenus("");
      }

      // console.log('navvvvvbar current url: ', window.location.pathname)
      setMenuFromApi(menuItems);
      // props.setUserLoggedIn(5);
      // console.log('sidebar changed', props.userLoggedIn);
      // let m = {id:1};
      // setActiveElementIdsdddd(1);
      // props.setUserLoggedIn('sidebar component called');
    }).catch(e => {
      // console.log('Error (sidebar.jsx)::Failed to get menu content');
      setMenuFromApi([]);
    });
  }, [props.userLoggedIn, window.location.pathname]);
  // }, [ localStorage.getItem('user')]);

  const activeElementIdHandler = (element) => {
    props.adminActiveMenuHandler(element);

    let elementId = element.id;
    let oldIds = [];
    let ids = [elementId];
    let parentIds = (elementId) => {
      let currentMenuFromApiItemParentId = null;
      menuFromApi.forEach((item) => {
        if (item.id === elementId) {
          currentMenuFromApiItemParentId = item.parentId;
        }
      });

      if (currentMenuFromApiItemParentId) {
        ids.push(currentMenuFromApiItemParentId);
        oldIds.push(currentMenuFromApiItemParentId);
        parentIds(currentMenuFromApiItemParentId);
      }
    };

    parentIds(elementId);

    if (JSON.stringify(activeElementIds) === JSON.stringify(ids)) {
      setActiveElementIds(oldIds);
    } else {
      setActiveElementIds(ids);
    }
  };

  const newMenuHandler = (
    parentId,
    name,
    isPost = null,
    icon = null,
    order = 1000
  ) => {
    let newEl = {
      name: name,
      parentId: parentId,
      icon: icon,
      link: isPost,
      order: order
    };

    console.log("newEl", newEl);

    let newMenu = [...menuFromApi, newEl];

    post("/navbar-menus", {
      data: [...newMenu]
    }).then((data) => {
      console.log("res", data.data.data);
      setMenuFromApi([...data.data.data]);
    });

    let newEl2 = {};
    let newMenu2 = [];
    newMenu.forEach((item) => {
      if (item.id >= 0) {
        newMenu2.push(item);
      } else {
        let tmpItem = {};
        tmpItem.name = item.name;
        tmpItem.parentId = item.parentId;
        tmpItem.icon = item.icon;
        tmpItem.link = item.link;
        tmpItem.order = item.order;
        tmpItem.id = newMenu.length;

        newEl2.name = item.name;
        newEl2.parentId = item.parentId;
        newEl2.icon = item.icon;
        newEl2.link = item.link;
        newEl2.order = item.order;
        newEl2.id = newMenu.length;

        newMenu2.push(tmpItem);
      }
    });
  };

  const editMenuHandler = (
    menuId,
    menuParentId,
    menuName,
    menuIcon = null,
    menuOrder = null
  ) => {
    let data = [];
    if (menuParentId === "currentParent") {
      data = [
        {
          name: menuName,
          icon: menuIcon,
          order: menuOrder
        }
      ];
    } else {
      data = [
        {
          parentId: menuParentId,
          name: menuName,
          icon: menuIcon,
          order: menuOrder
        }
      ];
    }
    post("/navbar-menus-edit/" + menuId, { data }).then((data) => {
      console.log("editMenuHandler menu id:" + menuId);
      setMenuFromApi([...data.data.data]);
    });
  };

  const deleteMenuHandler = (menuId) => {
    console.log("deleteMenuHandler menu id" + menuId);
    get("/navbar-menus-delete/" + menuId).then((data) => {
      // console.log(data);
      setMenuFromApi([...data.data.data]);
    });
  };

  // start++++ add menu btn
  const menuInputsRef = useRef();
  const addMenuBtnHandler = () => {
    if (menuInputsRef.current.style.display === "none") {
      menuInputsRef.current.style.display = "block";
    } else {
      menuInputsRef.current.style.display = "none";
    }
    console.log("clicked parent menu btn");
  };
  const addSubMenuHandler = (e) => {
    e.preventDefault();
    let parentId = null;
    let subMenuName = e.target.children[0].value;
    let icon = e.target.children[1].value;
    if (icon.length == 0) {
      //default icon for menus
      icon = "fas fa-bars";
    }

    newMenuHandler(parentId, subMenuName, null, icon);

    e.target.children[0].value = "";
    e.target.children[1].value = "";
    if (menuInputsRef.current.style.display === "none") {
      menuInputsRef.current.style.display = "block";
    } else {
      menuInputsRef.current.style.display = "none";
    }
  };
  // end------ add menu btn

  // const searchInputRef = useRef();
  // const [searchInputValue, setSearchInputValue] = useState("");

  const handleSearchInput = (e) => {
    e.preventDefault();
  }

  // const [searchResults, setSearchResults] = useState(['hi']);
  // function sleep(duration) {
  //   return new Promise((resolve) => {
  //     setTimeout(() => {
  //       resolve();
  //     }, duration);
  //   });
  // }
  const [open, setOpen] = useState(false);
  // const [autocompleteWidth, setAutocompleteWidth] = useState(250);
  const [searchResults, setSearchResults] = useState([]);
  // const [selectedSearchOption, setSelectedSearchOption] = useState(null);
  const loading = open && searchResults.length === 0;
  useEffect(() => {
    if (!open) {
      setSearchResults([]);
    }
  }, [open]);
  const searchInputChangeHandler = (e) => {

    // console.log('asdasdf', e.target)
    // setSearchInputValue(e.target.value);
    const keyword = e.target.value;
    if (keyword.length > 1) {
      // setAutocompleteWidth();
    }
    get(`search?s=${keyword}`, null).then(data => {
      // console.log('sdlkfjjsdfkk', [{ title: 'The Shawshank Redemption s', year: 1994 }, { title: 'Inception s', year: 2011 }])
      // console.log('sdlkfjjsdfkk', data.data.data)
      // setSearchResults([{ title: 'The Shawshank Redemption s', year: 1994 }, { title: 'Inception s', year: 2011 }]);
      setSearchResults(data.data.data);
    });
  };
  // useEffect(() => {
  //   let active = true;
  //   if (!loading) {
  //     return undefined;
  //   }
  //   (async () => {
  //     await sleep(1); // For demo purposes.
  //     if (active) {
  //       setSearchResults([...searchResults]);//...topFilms
  //     }
  //   })();
  //   return () => {
  //     active = false;
  //   };
  // }, [loading]);

  const [parentMenus, setParentMenus] = useState("");


  return (
    // <div>k</div>
    <div>

      <div className="accordian">
        <div style={{ margin: "10px 5px" }}>
          <form action="" onSubmit={handleSearchInput} style={{ paddingBottom: '70px' }}>

            <Autocomplete
              sx={{ width: open ? '93vw' : 250, zIndex: 10, backgroundColor: open ? 'lightgray' : 'inherit', position: open ? 'fixed' : 'absolute' }}
              open={open}
              onOpen={() => {
                setOpen(true);
              }}
              onClose={() => {
                setOpen(false);
              }}
              isOptionEqualToValue={(option, value) => option.content === value.content}
              getOptionLabel={(option) => {
                // console.log('hhhho option', option)
                return option.content
              }}
              options={searchResults}
              loading={loading}
              // onChange={handleAutocompleteInput}
              onChange={(event, option) => {
                window.location.href = '/posts/' + option.link;
              }}
              renderInput={(params) => (
                <TextField
                  onChange={searchInputChangeHandler}
                  {...params}
                  label="Search in contents"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />

          </form>
        </div>

        {((localStorage.getItem('userToken')) && (!useLocation().search.length)) ? <button
          className="menu-add-btn"
          onClick={() => {
            addMenuBtnHandler();
          }}
          style={{
            // marginLeft: "5px",
            marginBottom: "5px",
            padding: "3px",
            fontSize: "20px",
            border: "none",
            backgroundColor: "white",
            fontWeight: "bold"
          }}
        >
          <i style={{ marginRight: "2px" }} className="fa fa-plus "></i>
          Add a folder
        </button> : null}
        <form
          action=""
          onSubmit={(e) => {
            addSubMenuHandler(e);
          }}
          ref={menuInputsRef}
          style={{ display: "none" }}
          className='container-sidebar'
        >
          <input className="input-sidebar" type="text" name="name" placeholder="Folder name" />
          {/* <input type="text" name="icon" placeholder="Icon (ex: fas fa-bar)" /> */}
          <button className="btn-sidebar">add folder</button>
        </form>
        {menuFromApi.map((menu1, index) => {
          return (
            <Menu1
              key={index}
              menu1={menu1}
              menuFromApi={menuFromApi}
              activeElementIdHandler={activeElementIdHandler}
              activeElementIds={activeElementIds}
              newMenuHandler={newMenuHandler}
              editMenuHandler={editMenuHandler}
              deleteMenuHandler={deleteMenuHandler}
            />
          );
        })}
      </div>
      <div style={{ position: 'fixed', bottom: 0, left: 10, color: 'gray', fontSize:9 }}>{parentMenus}</div>
    </div>
  );
};

export default Sidebar;

{
  /* <div id="accordian">
  <ul class="show-dropdown">
      <li>
          <a href="javascript:void(0);"><i class="fas fa-tachometer-alt"></i>Dashboard</a>
      </li>
      <li>
          <a href="javascript:void(0);"><i class="far fa-address-book"></i>Address Book</a>
          <ul>
              <li><a href="javascript:void(0);">Reports</a></li>
              <li><a href="javascript:void(0);">Search</a></li>
              <li><a href="javascript:void(0);">Graphs</a></li>
              <li><a href="javascript:void(0);">Settings</a></li>
          </ul>
      </li>
      <li class="active">
          <a href="javascript:void(0);"><i class="far fa-clone"></i>Components</a>
          <ul class="show-dropdown">
              <li><a href="javascript:void(0);">Today's tasks</a></li>
              <li class="active">
                  <a href="javascript:void(0);">DrillDown (active)</a>
                  <ul class="show-dropdown">
                      <li><a href="javascript:void(0);">Today's tasks</a></li>
                      <li class="active"><a href="javascript:void(0);">Urgent</a></li>
                      <li>
                          <a href="javascript:void(0);">Overdues</a>
                          <ul>
                              <li><a href="javascript:void(0);">Today's tasks</a></li>
                              <li><a href="javascript:void(0);">Urgent</a></li>
                              <li><a href="javascript:void(0);">Overdues</a></li>
                              <li><a href="javascript:void(0);">Recurring</a></li>
                              <li>
                                  <a href="javascript:void(0);">Calendar</a>
                                  <ul>
                                      <li><a href="javascript:void(0);">Current Month</a></li>
                                      <li><a href="javascript:void(0);">Current Week</a></li>
                                      <li><a href="javascript:void(0);">Previous Month</a></li>
                                      <li><a href="javascript:void(0);">Previous Week</a></li>
                                      <li><a href="javascript:void(0);">Next Month</a></li>
                                      <li><a href="javascript:void(0);">Next Week</a></li>
                                      <li><a href="javascript:void(0);">Team Calendar</a></li>
                                      <li><a href="javascript:void(0);">Private Calendar</a></li>
                                      <li><a href="javascript:void(0);">Settings</a></li>
                                  </ul>
                              </li>
                          </ul>
                      </li>
                      <li><a href="javascript:void(0);">Recurring</a></li>
                      <li><a href="javascript:void(0);">Settings</a></li>
                  </ul>
              </li>
              <li>
                  <a href="javascript:void(0);">Overdues</a>
                  <ul>
                      <li><a href="javascript:void(0);">Today's tasks</a></li>
                      <li><a href="javascript:void(0);">Urgent</a></li>
                      <li><a href="javascript:void(0);">Overdues</a></li>
                      <li><a href="javascript:void(0);">Recurring</a></li>
                      <li><a href="javascript:void(0);">Settings</a></li>
                  </ul>
              </li>
              <li><a href="javascript:void(0);">Recurring</a></li>
              <li><a href="javascript:void(0);">Settings</a></li>
          </ul>
      </li>
      <li>
          <a href="javascript:void(0);"><i class="far fa-calendar-alt"></i>Calendar</a>
          <ul>
              <li><a href="javascript:void(0);">Current Month</a></li>
              <li><a href="javascript:void(0);">Current Week</a></li>
              <li><a href="javascript:void(0);">Previous Month</a></li>
              <li><a href="javascript:void(0);">Previous Week</a></li>
              <li><a href="javascript:void(0);">Next Month</a></li>
              <li><a href="javascript:void(0);">Next Week</a></li>
              <li><a href="javascript:void(0);">Team Calendar</a></li>
              <li><a href="javascript:void(0);">Private Calendar</a></li>
              <li><a href="javascript:void(0);">Settings</a></li>
          </ul>
      </li>
      <li>
          <a href="javascript:void(0);"><i class="far fa-chart-bar"></i>Charts</a>
          <ul>
              <li><a href="javascript:void(0);">Global favs</a></li>
              <li><a href="javascript:void(0);">My favs</a></li>
              <li><a href="javascript:void(0);">Team favs</a></li>
              <li><a href="javascript:void(0);">Settings</a></li>
          </ul>
      </li>
      <li>
          <a href="javascript:void(0);"><i class="far fa-copy"></i>Documents</a>
      </li>
      <li>
          <a href="javascript:void(0);"><i class="far fa-bookmark"></i>Bookmarks</a>
      </li>
      <li>
          <a href="javascript:void(0);"><i class="far fa-envelope"></i>Mail</a>
      </li>
      <li>
          <a href="javascript:void(0);"><i class="far fa-heart"></i>Favorite</a>
      </li>

  </ul>
</div> */
}
