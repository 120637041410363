import IconButton from "@mui/material/IconButton";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import CodeIcon from "@mui/icons-material/Code";
import AddBoxTwoToneIcon from '@mui/icons-material/AddBoxTwoTone';
import { useState } from "react";

const AddEditorSingleButtonComponent = (props) => {
  let color = props.type === "text" ? "primary" : "secondary";

  const [language, setLanguage] = useState('php');

  const handleLanguage = (e)=>{
    // console.log('handleLanguage', e.target.value)
    setLanguage(e.target.value);
  }
  return (
    // <button
    //     onClick={() => {
    //       props.addEditorHandler(props.type, props.index);
    //     }}
    //   >
    //     {props.children}
    //   </button>

    <IconButton
      color={color}
      aria-label="add to shopping cart"
      onClick={() => {
        props.addEditorHandler(props.type, props.index, language);
      }}
      style={{ maxHeight: "5px" }}
    >
      {props.type === "text" ? 
        <div style={{ fontSize: 14, color: 'black' }}>Add text editor 
          {/* <TextFieldsIcon /> */}
        </div> :
        <div style={{ display: 'flex'}}>
          <select name="lang" id="" style={{ border: 'none' }} onChange={handleLanguage}>
            <option value="js">Add code</option>
            <option value="js">js</option>
            <option value="php">php</option>
            <option value="plaintext">plaintext</option>
            <option value="markdown">markdown</option>
            <option value="html">html</option>
            <option value="css">css</option>
            <option value="scss">scss</option>
            <option value="jsx">jsx</option>
            <option value="vue">vue</option>
            <option value="typescript">typescript</option>
            <option value="c">c</option>
            <option value="go">go</option>
            <option value="python">python</option>
            <option value="shell">shell</option>
            <option value="dockerfile">dockerfile</option>
            <option value="nginx">nginx</option>
            <option value="yaml">yaml</option>
            <option value="xml">xml</option>
            <option value="json">json</option>
            <option value="ini">ini</option>
          </select>
          {/* <AddBoxTwoToneIcon /> */}
        </div>}
    </IconButton>
  );
};

export default AddEditorSingleButtonComponent;
