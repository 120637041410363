const AddPageButton = (props) => {
  const clickHandler = () => {
    props.addPageBtnHandler(props.menu.name, props.menu.link);
    props.hideOtherFormsHandler();
  };

  return (
    <button
      className="menu-add-btn"
      onClick={clickHandler}
      style={{
        marginLeft: "0px",
        padding: "3px",
        fontSize: "12px",
        borderTop: "none",
        borderRight: "1px solid gray",
        borderBottom: "none",
        borderLeft: "none",
        backgroundColor: "white"
      }}
    >
      <i style={{ marginRight: "2px" }} className="fa fa-plus "></i>
      Note
    </button>
  );
};

export default AddPageButton;
